import { useSearchParams } from 'next/navigation';

export const useQueryParam = (key: string): string | null => {
  const searchParams = useSearchParams();

  if (!searchParams) {
    return null;
  }

  return searchParams.get(key);
};
