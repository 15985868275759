'use client';

import { clsx } from 'clsx';
import NextImage from 'next/image';
import { FallbackImage } from 'components/UI/FallbackImage';
import type { PropsWithClassName } from 'types/react-props';
import { getImageLoader } from 'utils/imageLoader';
import styles from './Image.module.scss';

export const imageSizes = [124, 240, 360, 480, 768, 1024, 1200];

const cropDefault = 'c16_9';

export type AllowedCropTypes = 'c2_1' | 'c4_3' | 'c3_4' | 'c1_1' | 'c21_9' | typeof cropDefault;

export type ApiImageProps = {
  readonly id: string;
  readonly baseUrl: string;
  readonly crop: AllowedCropTypes;
  readonly defaultUrl: string;
  readonly filename: string;
  readonly originalWidth: number;
  readonly originalHeight: number;
};

export type ImageProps = PropsWithClassName<{
  width?: number;
  alt?: string;
  title?: string;
  readonly image?: ApiImageProps;
  src?: string;
  fallback?: {
    className: string;
  };
  mimeType?: string;
  priority?: boolean;
  cropOverride?: AllowedCropTypes;
  sizes?: string;
}>;

const getSizes = (width: number) => {
  return imageSizes
    .filter((point) => point <= width)
    .map((imageSize) => `(max-width: ${imageSize}px) ${imageSize}px`)
    .join(', ');
};

const getDimensions = (crop: string, width = 870) => {
  const aspectRatio = getAspectRatioValue(crop);

  return {
    imageWidth: width,
    imageHeight: Math.round(width / aspectRatio),
  };
};

const getAspectRatioValue = (crop: string) => {
  const [width, height] = crop
    .replace('c', '')
    .split('_')
    .map((x) => parseInt(x, 10));

  return width / height;
};

const createOptimizedImage = ({
  baseUrl,
  fileName,
  crop,
  width,
  className,
  title,
  mimeType,
  priority,
  sizes,
}: {
  baseUrl: string;
  fileName: string;
  crop: AllowedCropTypes;
  width: number;
  className: string;
  title?: string;
  priority?: boolean;
  mimeType?: string;
  sizes?: string;
}) => {
  const { imageWidth, imageHeight } = getDimensions(crop, width);

  const { loader, unoptimized } = getImageLoader(baseUrl, fileName, crop, mimeType);

  return (
    <NextImage
      loader={loader}
      alt={fileName}
      unoptimized={unoptimized}
      className={className}
      height={imageHeight}
      width={imageWidth}
      src={baseUrl}
      sizes={sizes ?? getSizes(imageWidth)}
      title={title}
      priority={priority}
      suppressHydrationWarning
    />
  );
};

const Image = (props: ImageProps) => {
  const { alt, width, className, title, priority, cropOverride, sizes } = props;

  if (!props.image) {
    if (props.src && width) {
      return createOptimizedImage({
        baseUrl: props.src,
        fileName: alt ?? 'image',
        crop: cropOverride ?? 'c16_9',
        width,
        className: clsx(styles.image, className),
        title,
        priority,
        sizes,
      });
    }

    return <FallbackImage className={props.fallback?.className} />;
  }

  const { baseUrl, filename, crop = cropDefault, originalWidth } = props.image;
  const usedWidth = width ?? originalWidth;

  return createOptimizedImage({
    baseUrl,
    fileName: alt ?? filename,
    crop: cropOverride ?? crop,
    width: usedWidth,
    className: clsx(styles.image, className),
    title,
    priority,
    ...(props.mimeType ? { mimeType: props.mimeType } : null),
    sizes,
  });
};

export default Image;
